import * as React from 'react';

const EconomySvg = (props) => (
  <svg xmlns='http://www.w3.org/2000/svg' width={31} height={34.001} viewBox='0 0 31 34' {...props}>
    <path
      fill='#fff'
      stroke='rgba(0,0,0,0)'
      strokeMiterlimit={10}
      d='M11.677 33.501a.928.928 0 0 1-.527-1.643l.7-.579A14.878 14.878 0 0 1 .5 17.001a14.475 14.475 0 0 1 4.394-10.4 1.04 1.04 0 0 1 1.452 0 .994.994 0 0 1 0 1.425 12.494 12.494 0 0 0-3.792 8.975 12.83 12.83 0 0 0 9.119 12.133l-.254-.3a.932.932 0 0 1 .81-1.528.958.958 0 0 1 .645.329l2.626 3.073-3.128 2.575a.962.962 0 0 1-.612.218h-.083Zm12.977-6.1a.993.993 0 0 1 0-1.424 12.5 12.5 0 0 0 3.792-8.98 12.83 12.83 0 0 0-9.121-12.13l.255.3a.932.932 0 0 1-.811 1.527.955.955 0 0 1-.645-.329L15.5 3.294 18.627.719a.963.963 0 0 1 .694-.218.928.928 0 0 1 .528 1.643l-.7.579A14.877 14.877 0 0 1 30.5 17.001a14.478 14.478 0 0 1-4.394 10.4 1.041 1.041 0 0 1-1.452 0ZM6.121 16.989A9.385 9.385 0 0 1 15.6 7.695a9.386 9.386 0 0 1 9.474 9.294 9.386 9.386 0 0 1-9.474 9.294 9.385 9.385 0 0 1-9.479-9.294Zm6.08 4.663a.783.783 0 0 0 .791.776h1.747v.628a.792.792 0 0 0 1.583 0v-.628h.068a3.046 3.046 0 1 0 0-6.09h-.068v-2.985h1.748a.776.776 0 1 0 0-1.553h-1.749v-.584a.792.792 0 0 0-1.583 0v.585h-.068a3.046 3.046 0 1 0 0 6.091h.068v2.985h-1.746a.783.783 0 0 0-.792.775Zm4.121-.776V17.89h.068a1.493 1.493 0 1 1 0 2.985Zm-1.65-4.538a1.493 1.493 0 1 1 0-2.985h.067v2.985Z'
      data-name='Union 102'
    />
  </svg>
);
export default EconomySvg;
